.member{
    display: flex;
    width: 100%;
    background-color: #4479ff;
    min-height: 50vh;
    flex-direction: column;
}

.member-top-content{
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 50px 0;
}

.member-top-content >p > strong{
    font-weight: bolder;
    font-size: 8vw;
    font-family: fangsong;
    color: #fff;
}



.member-top-content > p {
    color: white !important;
    text-align: center;
    font-size: 22px;
}

.member-top-content > button {
    background-color: #fff;
    color: #000;
    padding: 10px 50px;
    border-radius: 25px;
    border: none;
    outline: none;
    font-size: 1.2rem;
    cursor: pointer;
}

.member-top-content > button:hover{
    background-color: #eee;
}

.member-grid{
    display: flex;
    width: 100%;
    border-top: 1px solid #fff;
    
}

.member-grid-content{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 30px;
}

.member-grid-content > .grid-left:last-child{
    border-left: 1px solid #fff;
}

.grid-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    padding: 30px 20px;
}

.grid-left > p > strong{
    font-weight: bolder;
    font-size: 3rem;
    font-family: fangsong;
    color: #fff;
    padding: 0;
    margin: 0;
}

.grid-left > img{
    width: 300px;
    object-fit: contain;
    margin-bottom: 10px;
}

.grid-left > p {
    color: white;
    font-size: 22px;
}