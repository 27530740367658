/* examples/custom-animation.css */

.modal {
  display: grid;
  place-items: center;
  height: 100%;
  justify-content: center;
}

.modal > h2 {
  font-size: 3rem;
  font-family: "Times New Roman", Times, serif;
}

.customOverlay {
  background: rgba(241, 241, 241, 0.7);
}
.customModal {
  background: #ffffff;
  max-width: 400px;
  width: 100%;
  height: 90vh;
}

.signInButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signInButtons > button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  margin: 10px 0px;
  background-color: #ffffff;
  border: 1px solid rgba(8, 8, 8, 1);
  border-radius: 33px;
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
}

.modal-body {
  display: flex;
  align-items: center;
}

.modal-body > span {
  color: green;
  font-weight: 500;
  margin: 0px 10px;
  cursor: pointer;
}

.modal-footer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: rgba(82, 82, 82, 0.7);
}

@media screen and (min-width: 600px) {
  .customModal {
    max-width: 500px;
  }
}

@keyframes customEnterOverlayAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveOverlayAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
  }
}
