div[contenteditable="true"] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin: 50px;
  min-height: 50vh;
  border: 1px dashed lightgray;
  padding: 30px;
  font-size: 18px;
  box-shadow: 2px 4px 20px 0px lightgrey;
}

pre {
  margin: 50px;
  overflow-y: hidden;
  border: 1px dashed lightgray;
  padding: 5px;
  min-height: 50px;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-align: center;
  font-weight: bold;
  box-shadow: 2px 4px 20px 0px lightgrey;
}

.pub-button > button {
  display: flex;
  margin-left: auto;
  margin-right: 50px;
  margin-top: 10px;
  padding: 5px 15px;
  /* margin: 0 10px; */
  border-radius: 33px;
  outline: none;
  border: 1px solid rgba(47, 47, 47, 0.623);
  cursor: pointer;
  background-color: green;
  /* border: 1px solid green; */
  color: #fff;
  cursor: pointer;
}
