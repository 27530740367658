.post {
  display: flex;
  width: 100%;
  padding: 20px 0px;
  align-items: center;
  justify-content: center;
}

.post-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1190px;
}

.post-top {
  display: flex;
  flex-direction: row;
  margin-right: auto;
  align-items: center;
  font-weight: 600;
}

.rcm-post-wrapper {
  display: flex;
  width: 100%;
}

.rcm-post-conatiner {
  display: flex;
  width: 100%;
}

.rcm-posts {
  display: grid;
  margin: 0px 10px;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 400px;
  column-gap: 1fr;
}

.rcm-post {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin: 5px 0px;
}

.rcm-post-left {
  display: flex;
  font-size: 30px;
  color: rgb(216, 216, 216);
  font-weight: 800;
}

.rcm-post-right {
  display: flex;
  width: 100%;
  margin: 10px 0;
}

.rcm-post-content {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}

.top {
  display: flex;
  align-items: center;
  width: 100%;
  /* margin: 0px 10px; */
}

.top > img {
  border-radius: 30%;
  width: 20px;
  margin-right: 10px;
  /* margin: 0px 10px; */
}

.top > span {
  font-size: 14px;
  font-weight: 500;
  color: rgba(8, 8, 8, 1);
}

.content {
  display: flex;
  width: 100%;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 700;
}

.footer {
  display: flex;
  margin: 5px 0;
  color: rgb(175, 175, 175);
}

@media screen and (max-width: 600px) {
  .post-top {
    padding: 0px 20px;
  }

  .rcm-post-conatiner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rcm-posts {
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: center;
    justify-content: center;
    margin: 10px 20px;
  }

  /* .rcm-post-wrapper {
    width: 100vw;
    margin: 0px 20px;
  } */
  .post-container {
    max-width: 100vw;
  }
}

@media screen and (min-width: 600px) {
  .rcm-posts {
    display: grid;
    /* margin: 0px 10px; */
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 400px;
  }
}

@media screen and (min-width: 1024px) {
  .rcm-posts {
    display: grid;
    /* margin: 0px 10px; */
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 200px;
  }
}
