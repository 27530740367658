.landing-recommended-post {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid #999;
}

.landing-recommended-post-container {
  display: flex;
  width: 100%;
}

.landing-recommended-left {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.landing-top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.landing-top > img {
  width: 20px;
  object-fit: contain;
  border-radius: 5px;
  margin-right: 10px;
}

.landing-top > span {
  font-weight: 600;
  font-size: 14px;
}

.landing-content {
  font-weight: 700;
  font-size: 15px;
  flex: 1;
}

.landing-footer {
  display: flex;
  color: rgb(139, 139, 139);
}

.landing-footer > .icons {
  margin-left: auto;
}
.landing-footer > .icons > span {
  cursor: pointer;
  margin-right: 10px;
}

h1 {
  font-size: 18px;
  font-weight: 500;
}
