.singleBlog {
    display: flex;
    flex-direction: column;
    background-color: white;
    /* width: fit-content; */
  }

  .singleBlog__title p,
  .singleBlog__title h1,
  .singleBlog__title h2,
  .singleBlog__title h3,
  .singleBlog__title h4,
  .singleBlog__title h5,
  .singleBlog__title h6,
  .singleBlog__title li,
  .singleBlog__title strong {
    font-family: "Lato", sans-serif;
    font-size: 32px;
    font-weight: 600;
  }
  
  .singleBlog__title {
    margin: 0 auto;
    font-weight: 700 !important;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 10px;
    color: var(--main-color);
    font-size: 50px !important;
    width: 86%;
    font-family: "Lato", sans-serif;
  }

  .singleBlog_author{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px
  }

  .singleBlog_left{
    display: flex;
    align-items: center;
    width: 50%;
  }

  .author-details{
    display: flex;
    align-items: center;
    width: 100%;
  }

  .author-name{
    display: flex;
    margin-left: 10px;
    flex-direction: column;
  }
  
  .singleBlog__info {
    width: 86%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-family: "Lato", sans-serif;
  }
  
  .singleBlog__info span {
    font-family: "Lato", sans-serif;
  }
  
  .singleBlog__info button {
    color: #ffaa00;
    border: 1px solid #ffaa00;
    text-transform: uppercase;
    padding: 4px 8px;
    font-size: 18px;
    background-color: transparent;
    font-family: "Lato", sans-serif;
  }
  
  .singleBlog__banner {
    width: 100%;
    object-fit: contain;
    margin: 40px 0;
    max-height: 500px;
  }
  
  .singleBlog__body {
    width: 50%;
    margin: 0 auto;
    font-family: "Lato", sans-serif;
  }
  
  .singleBlog__body p,
  .singleBlog__body li {
    font-size: 20px;
    line-height: 1.6em;
  }
  
  .singleBlog__body p,
  .singleBlog__body h1,
  .singleBlog__body h2,
  .singleBlog__body h3,
  .singleBlog__body h4,
  .singleBlog__body h5,
  .singleBlog__body h6,
  .singleBlog__body li,
  .singleBlog__body strong {
    font-family: "Lato", sans-serif;
    margin: 0;
  }
  
  .singleBlog__body > iframe {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 300px;
    overflow: hidden !important;
  }
  
  .singleBlog__body a {
    color: #292a33;
    font-weight: 200;
    max-width: 90%;
  }
  
  .singleBlog__body a > strong > u {
    width: 100%;
    /* text-decoration: none; */
  }
  
  .singleBlog__body img {
    width: 100%;
  }
  
  .singleBlog__share {
    margin: 40px 0;
    display: flex;
  }
  
  .sharethis-inline-share-buttons {
    display: flex;
  }
  
  /* .blogCard__vertical {
    width: 100%;
  } */
  
  @media only screen and (max-width: 600px) {
    time {
      font-size: small;
      color: #aaa;
      padding: 10px 7%;
    }
    .singleBlog__body a {
      width: 100%;
      overflow: hidden;
    }
  
    strong > u {
      width: 50%;
      overflow: hidden;
    }
  
    .singleBlog__title {
      font-size: 46px;
    }
  }